import React from 'react'
import { PageProps, graphql } from 'gatsby'

import { PostIndexQuery } from '../../graphql-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostArticle from '../components/organisms/PostArticle'
import AppPagination from '../components/molecules/AppPagination'

const PostList: React.FC<PageProps<PostIndexQuery>> = ({ data, path, pageContext }) => (
  <Layout>
    <SEO title="comilablog" />
    {data.allMarkdownRemark.nodes.map((post, i) => (
      <PostArticle post={post} key={i.toString()} excerpt></PostArticle>
    ))}
    <AppPagination currentPath={path} pageContext={pageContext}></AppPagination>
  </Layout>
)

export default PostList

export const pageQuery = graphql`
  query PostList ($limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^\/post\//" } } }
      sort: {fields: frontmatter___date, order: DESC},
      limit: $limit
      skip: $skip
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
        }
        excerpt(format: HTML)
        html
      }
    }
  }
`
