import React from 'react'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import AppButton from '../atoms/AppButton'

type Props = {
  currentPath: string
  pageContext: any
  children?: never
}

const AppPagination: React.FC<Props> = ({ currentPath, pageContext }) => {
  const currentPage = pageContext.humanPageNumber
  const totalPages = pageContext.numberOfPages
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const getUrl = (page: number) => {
    const basePath = currentPath.replace(/\/[0-9]*$/, '')
    if (page === 1) {
      return basePath || '/'
    }
    return `${basePath}/${page}/`
  }

  return (
    <nav className="my-10">
      <ul className="flex justify-center list-none m-0">
        {!isFirst && (
          <li className="mx-2">
            <AppButton
              to={getUrl(currentPage - 1)}
              icon={faChevronLeft}
              outline
              className="w-10 h-10"
            />
          </li>
        )}
        {Array.from({ length: totalPages }).map((_, p) => (
          <li className="mx-2" key={p}>
            <AppButton
              to={getUrl(p + 1)}
              outline={(p + 1) !== currentPage}
              className={`w-10 h-10 ${(p + 1) === currentPage ? 'pointer-events-none' : ''}`}
            >
              {p + 1}
            </AppButton>
          </li>
        ))}
        {!isLast && (
          <li className="mx-2">
            <AppButton
              to={getUrl(currentPage + 1)}
              icon={faChevronRight}
              outline
              className="w-10 h-10"
            />
          </li>
        )}
      </ul>
    </nav>
  )
}

export default AppPagination
